// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation AddNewTagToDossier(
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
    $label: String!
  ) {
    addNewTagToDossier(
      id: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
      label: $label
    ) {
      id
      label
      label_color
      label_text_color
    }
  }
`;

export default MUTATION;
